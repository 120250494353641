import React from "react";
import NavbarCrackerjack from "components/Navbar-Crackerjack/navbar-crackerjack";
import IntroWithSlider from "components/Intro-with-slider/intro-with-slider";
import Numbers from "components/Numbers/numbers";
import Blogs2 from "components/blogs/Blogs2/blogs2";
import CallToAction from "components/cta-crackerjack/cta-crackerjack";
import Footer from "components/Footer/footer";
import ServicesCrackerjack from "components/Services-crackerjack/services-crackerjack";
import AboutUsCrackerjack from "components/About-us-crackerjack/about-us-crackerjack";
import WorksStyle3 from "components/Works-style3/works-style3";
import FullTestimonials from "components/Full-testimonials/full-testimonials";
import LightTheme from "layouts/Light";
import Intro2 from "components/Intro2/intro2";
import IntroNoSlider from "components/Intro-no-slider/intro-no-slider";

const Homepage1 = () => {
  const fixedSlider = React.useRef(null);
  const MainContent = React.useRef(null);
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    setTimeout(() => {
      if (fixedSlider.current) {
        var slidHeight = fixedSlider.current.offsetHeight;
      }
      if (MainContent.current) {
        MainContent.current.style.marginTop = slidHeight + "px";
      }
    }, 1000);

    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [fixedSlider, MainContent, navbarRef]);

  return (
    <LightTheme>
      <NavbarCrackerjack nr={navbarRef} lr={logoRef} />
      <IntroNoSlider sliderRef={fixedSlider} />
      <div ref={MainContent} className="main-content">
        <ServicesCrackerjack />
        <AboutUsCrackerjack />
        <CallToAction img="/img/patrn1.png" />
        <Footer />
      </div>
    </LightTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Crackerjack Polka Band</title>
    </>
  )
}

export default Homepage1;
