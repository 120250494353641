import React from 'react'
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";

const AboutUs4 = () => {
  const [isOpen, setOpen] = React.useState(false);
  return (
    <section className="about-cr">
    <a name="about-us"></a>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 img md-mb50">
            <img src="/img/intro/Crackerjack1_sq.jpg" alt="" />
          </div>
          <div className="col-lg-4 valign">
            <div className="cont full-width">
              
              <h6>
                Authentic German Music<br />
                and more!
              </h6>
              {typeof window !== "undefined" && (
                <ModalVideo
                  channel="vimeo"
                  autoplay
                  isOpen={isOpen}
                  videoId="127203262"
                  onClose={() => setOpen(false)}
                />
              )}
              <div className="vid-area">
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs4