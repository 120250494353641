import React from "react";
import featuresData from "data/sections/features.json";

const Services4 = ({serviceMB50}) => {
  return (
    <section className="services box section-padding">
    <a name="samples"></a>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="sec-head  text-center">
              <h6 className="wow fadeIn" data-wow-delay=".5s">
                Performing in the Sierra Foothills and Greater Sacramento Area
              </h6>
              <h3>
                Crackerjack Polka Band
              </h3>
            </div>
          </div>
        </div>
        <h3>Peanuts Polka</h3>
        <div className="row">
          <audio controls>
            <source src="audio/PEANUTS POLKA3_21_P.mp3" type="audio/mpeg" />
            Your browser does not support the audio element. 
          </audio>
        </div>
        <br /><br />
        <h3>Im Munchen Steht Ein Hofbrau Haus</h3>
        <div className="row">
          <audio controls>
            <source src="audio/IN MUNCHIN HAUS_16_P.mp3" type="audio/mpeg" />
            Your browser does not support the audio element. 
          </audio>
        </div>

        <br /><br />
        <h3>Clarinet Laendler</h3>
        <div className="row">
          <audio controls>
            <source src="audio/CLARINET LANDLEIR_26_P.mp3" type="audio/mpeg" />
            Your browser does not support the audio element. 
          </audio>
        </div>

        <br /><br />
        <h3>Dakota Polka</h3>
        <div className="row">
          <audio controls>
            <source src="audio/DAKOTA POLKA2_30_P.mp3" type="audio/mpeg" />
            Your browser does not support the audio element. 
          </audio>
        </div>

        <br /><br />
        <h3>Sweet Caroline</h3>
        <div className="row">
          <audio controls>
            <source src="audio/SWEET CAROLINE_22_P.mp3" type="audio/mpeg" />
            Your browser does not support the audio element. 
          </audio>
        </div>

        <br /><br />
        <h3>Liechtensteiner Polka</h3>
        <div className="row">
          <audio controls>
            <source src="audio/LEICHTENSTEINER POLKA_18_P.mp3" type="audio/mpeg" />
            Your browser does not support the audio element. 
          </audio>
        </div>

        <br /><br />
        <h3>Pennsylvania Polka</h3>
        <div className="row">
          <audio controls>
            <source src="audio/PENNSYLVANIA POLKA2_17_P.mp3" type="audio/mpeg" />
            Your browser does not support the audio element. 
          </audio>
        </div>

        <br /><br />
        <h3>Beer Barrel Polka</h3>
        <div className="row">
          <audio controls>
            <source src="audio/BEER BARREL POLKA 2_16_P.mp3" type="audio/mpeg" />
            Your browser does not support the audio element. 
          </audio>
        </div>

        <br /><br />
        <h3>Bei Mir Bist Du Schoen</h3>
        <div className="row">
          <audio controls>
            <source src="audio/BEI MIR BIST DU SCHOEN_25_P.mp3" type="audio/mpeg" />
            Your browser does not support the audio element. 
          </audio>
        </div>

        <br /><br />
        <h3>Heidi's Bier Haus</h3>
        <div className="row">
          <audio controls>
            <source src="audio/HEIDI'S BIER HAUS1_30_P.mp3" type="audio/mpeg" />
            Your browser does not support the audio element. 
          </audio>
        </div>

        <br /><br />
        <h3>Ein Prosit der Gemutlichkeit</h3>
        <div className="row">
          <audio controls>
            <source src="audio/EIN PROSIT_23_P.mp3" type="audio/mpeg" />
            Your browser does not support the audio element. 
          </audio>
        </div>
        <br /><br />


      </div>
    </section>
  );
};

export default Services4;
