import React from 'react'
import Split from '../Split'
import { Link } from 'gatsby'

const CallToAction = ({img}) => {
    return (
      <section
        className="call-action section-padding sub-bg bg-img"
        style={{ backgroundImage: `url(${img ? img : "/img/patrn.svg"})` }}
      >
      <a name="hire-us"></a>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-lg-9">
              <div className="content sm-mb30">
               
                  <h2 className="chars" >
                    Contact us today
                  </h2>
               
              </div>
            </div>

            <div className="col-md-4 col-lg-3 valign">
              <Link
                to={`mailto:devans@rollingorange.com`}
                className="butn bord curve wow fadeInUp"
                data-wow-delay=".5s"
              >
                <span>Get In Touch</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
}

export default CallToAction